
import {
  defineComponent,
  Ref,
  ref,
  onMounted,
watch,
} from "vue";
import { Modal } from "bootstrap";
import Api from "@/services/Api";
import ModalDadosLoja from "./components/ModalDadosLoja.vue";

interface Consultor {
  id: number,
  nome: string,
  email: string,
  regiao: string,
  cargo: string,
  created_at: string,
  updated_at: string
}


export default defineComponent({
  name: "CarteiraConsultor",

  components: {
    ModalDadosLoja
  },

  setup() {
    const modalDadosLoja: Ref<any> = ref()
    const loja: Ref<any> = ref({})

    const consultores: Ref<Consultor[]> = ref([])
    const arrayDados: Ref<any> = ref([])
    const consultorSelected: Ref<Consultor | null> = ref(null);

    const loadConsultores: Ref<boolean> = ref(false);
    const loadDados: Ref<boolean> = ref(false);
    const fastFind: Ref<string> = ref('');


    function openModal(data) {
      loja.value = data
      modalDadosLoja.value.show();
    }

    async function fechaModal() {
      modalDadosLoja.value.hide();
    }

    async function getConsultores(){
      loadConsultores.value = true
      try{
        const { data, error, message } = await Api.get("buscaUsuarioIpirangaPaginado")
        consultores.value = data.users

      }catch(error){
        console.log("error", error)
      }finally {
        loadConsultores.value = false
      }
    }

    async function getDadosCarteiraConsultor(idConsultor){
      loadDados.value = true
      try{
        const { data } = await Api.get("getDadosCarteiraConsultor", {idConsultor, fastFind: fastFind.value})
        arrayDados.value = data

      }catch(error){
        console.log("error", error)
      }finally {
        loadDados.value = false
      }
    }

  watch(
    () =>consultorSelected.value,
    () => {
      console.log("consultorSelected", consultorSelected.value)
      if(consultorSelected.value?.id){
        getDadosCarteiraConsultor(consultorSelected.value.id)
      }
    }
  )

  watch(
    () => fastFind.value,
    () => {
      if((consultorSelected.value?.id && fastFind.value.length > 2) || (consultorSelected.value?.id && !fastFind.value)){
        getDadosCarteiraConsultor(consultorSelected.value.id)
      }
    }
  )

  onMounted(async () => {
    modalDadosLoja.value = new Modal(document.querySelector("#modalDadosLoja"));
    getConsultores()
  })

    

    return {
      history,
      openModal,
      fechaModal,
      loja,
      loadConsultores,
      loadDados,
      fastFind,
      consultores,
      consultorSelected,
      arrayDados,
      getDadosCarteiraConsultor
    };
  },
});
