
import { defineComponent, Ref, ref, watch } from "vue";
import type { PropType } from "vue";



export default defineComponent({
  name: "ModalDadosLoja",
  components: {
  },
  props: {
    data: Object as PropType<any>,
    fechaModal: Function,
  },

  setup(props) {

  const loja: Ref<any> = ref({})

  function fecharModal(){
    props.fechaModal && props.fechaModal()
  }

  watch(
    () => props.data,
    (newValue) => {
      loja.value = newValue
      loja.value.status === 1 ? loja.value.status = 'Ativo' : loja.value.status = 'Inativo'
      loja.value.created_at = new Date(loja.value.created_at).toLocaleDateString('pt-BR')
    }
  )
   
    return {
      loja,
      fecharModal
    };
  },
});
